.container {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.containerProyecto {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

ul {
    list-style-type: none;
    padding: 0;
    padding-right: 0.5vmax;
}

.data {
    /* column-count: 2; */
    display: flex;
    flex-direction: row;
}

.dataProyecto {
    /* column-count: 2; */
    display: flex;
    flex-direction: row;
}

.caja1 {}

.caja2 {
    width: 190px;
}

.caja3 {}

.tittle {
    margin-top: 1vh;
    height: min-content;
}