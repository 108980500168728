.home-container {
    text-align: center;
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.background {
    background-color: white;
    width: 100vw;
    left: 0px;
    position: absolute;
    top: -19px;
    padding: 50px;
}

.App-logo {
    height: 30vmin;
    pointer-events: none;
}

.home-message-container{
    margin-top: 4vh;
}