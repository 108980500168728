.general-error-container {
    text-align: center;
}

.logo {
    height: 40vmin;
    pointer-events: none;
}

.general-error-logo{
    height: 20vmin;
    pointer-events: none;
}

.general-error-button-volver-crm{
    margin-top: 3vh !important;
}

.general-error-background {
    background-color: white;
    width: 100vw;
    left: 0px;
    position: absolute;
    top: -19px;
    padding: 50px;
    padding-bottom: 10px;
}