.App {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    /* text-align: center; */
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: rgb(218, 72, 72);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


/* .container-route{
  background-color: #161616;
  position: relative;
flex: 1;
  overflow-y: hidden;
} */

.circularProgress {
    color: #ffffff;
    position: fixed;
    top: 40%;
    left: 46%;
    z-index: 0;
}

.transition-group {
    flex: 1;
    overflow: hidden;
    position: relative;
    justify-content: center;
}

.container-route {
    background: rgb(224, 217, 217);
    display: flex;
    flex: 1;
}

.container-route .fade-appear,
.container-route .fade-enter {
    opacity: 0;
    transform: translateY(100%);
}

.container-route .fade-appear-done,
.container-route .fade-enter-done {
    transition: 0.5s ease-in;
    transform: translateY(0px);
    opacity: 1;
}

.fade-exit {
    transition: 0.5s ease-out;
    transform: translateY(0px);
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(100%);
}