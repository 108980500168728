.containerPaper {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}

.ModchildreOne {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ModchildreTwo {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ModchildreThree {}

.tituloMod {
    font-weight: bolder;
    font-size: 12px;
    color: black;
}

@media(max-width: 1560px) {
    .containerPaper {
        flex-direction: column;
        align-items: flex-start;
    }
    .ModchildreTwo {
        margin-left: 0;
    }
}