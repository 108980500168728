.planificar-container {
    text-align: center;
    padding-left: 2vw;
    padding-right: 2vw;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.planificar-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@media (min-width: 1350px) {
    .planificar-container {
        padding-left: 10vw;
        padding-right: 10vw;
    }
}